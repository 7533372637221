<template>
<router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import url("./assets/style/theme.css");
</style>
