export default [
  {
    id: 1,
    answer: "석가모니",
    question: "불교의 창시자. 본명 고타마 싯다르타.",
    page: "51",
  },
  {
    id: 2,
    answer: "불교",
    question: "석가모니가 창시한 종교, 평등과 자비 강조.",
    page: "51",
  },
  {
    id: 3,
    answer: "마우리아",
    question:
      "알렉산드로스의 인도 서북부 지역 침입 이후 찬드라굽타가 세운 왕조. 인도 북부 지역을 처음으로 통일.",
    page: "52",
  },
  {
    id: 4,
    answer: "아소카왕",
    question:
      "마우리아 왕조의 전성기를 이끈 왕. 인도 대부분 지역 통일, 곳곳에 사원과 불탑을 세워 불교 장려.",
    page: "52",
  },
  {
    id: 5,
    answer: "상좌부 불교",
    question:
      "마우리아 왕조 때 발전한 개인의 해탈을 강조하는 불교. 실론과 동남아시아 등지로 전파됨.",
    page: "52",
  },
  {
    id: 6,
    answer: "쿠샨",
    question:
      "마우리아 왕조의 쇠퇴 이후 1세기경 이란 계통의 유목민이 세운 왕조.",
    page: "53",
  },
  {
    id: 7,
    answer: "카니슈카왕",
    question:
      "2세기 중엽 쿠샨 왕조의 전성기를 이끈 왕. 간다라 지방을 중심으로 중앙아시아 영토까지 확보함.",
    page: "53",
  },
  {
    id: 8,
    answer: "대승 불교",
    question:
      "쿠샨 왕조 시기에 발달한 많은 사람(중생)의 구제를 강조하는 불교. 중앙아시아를 거쳐 중국, 한반도, 일본 등에 전해짐.",
    page: "53",
  },
  {
    id: 9,
    answer: "간다라 양식",
    question:
      "쿠샨 왕조의 간다라 지방에서 발달한 인도 문화와 헬레니즘 문화가 융합된 양식.",
    page: "53",
  },
  {
    id: 10,
    answer: "굽타",
    question: "쿠샨 왕조 이후 분열된 인도(북부)를 4세기경 통일한 왕조.",
    page: "54",
  },
  {
    id: 11,
    answer: "찬드라굽타 2세",
    question:
      "5세기 초 굽타 왕조의 전성기를 이끈 왕. 영토 확장 및 활발한 해상 무역으로 번영을 이룸.",
    page: "54",
  },
  {
    id: 12,
    answer: "힌두교",
    question:
      "굽타 왕조 시기에 브라만교와 불교, 인도의 민간 신앙이 융합하여 발전한 종교.",
    page: "54",
  },
  {
    id: 13,
    answer: "마누 법전",
    question:
      "카스트를 비롯하여 각종 의례와 관습, 법 등을 기록하여 힌두교도의 일상생활에 큰 영향을 준 법전.",
    page: "54",
  },
  {
    id: 14,
    answer: "산스크리트어",
    question:
      "지식 계층이 사용한 인도 고유의 언어로 다양한 문학 작품이 쓰여짐.",
    page: "55",
  },
  {
    id: 15,
    answer: "굽타 양식",
    question:
      "간다라 양식과 인도 고유의 양식이 융합되어 나타난 양식. 인물의 생김새나 옷차림 등에서 인도 고유의 특징을 잘 표현함.",
    page: "55",
  },
  {
    id: 16,
    answer: "홍무제",
    question:
      "원 말기에 농민 반란군을 이끌어 금릉(난징)을 수도로 명을 건국한 황제. 황제 독재권을 강화하고 한족 전통을 회복.",
    page: "103",
  },
  {
    id: 17,
    answer: "이갑제",
    question:
      "홍무제가 향촌 사회를 다스리기 위해 실시하여 농민이 직접 세금 징수와 치안 유지를 담당하게 한 제도.",
    page: "103",
  },
  {
    id: 18,
    answer: "토지 대장",
    question:
      "홍무제가 만든 토지에 대한 정보를 기록해 놓은 문서. 세금 걷는데 이용됨.",
    page: "103",
  },
  {
    id: 19,
    answer: "영락제",
    question:
      "수도를 베이징으로 옮기고 적극적인 대외 팽창 정책을 실시한 명의 황제. 몽골을 공격하고 베트남을 정복함.",
    page: "103",
  },
  {
    id: 20,
    answer: "정화",
    question:
      "1405년부터 1433년까지 일곱 차례의 항해에 나서 아프리카까지 진출한 명의 환관. 그 항해 덕분에 명은 국력을 과시하고 여러 나라와 조공 관계를 맺음.",
    page: "103",
  },
  {
    id: 21,
    answer: "신사",
    question: "유교적 소양을 갖춘 지식인이자 지주. 명•청대에 사회를 주도함.",
    page: "104",
  },
  {
    id: 22,
    answer: "양명학",
    question:
      "명 중엽에 등장한 올바른 지식(양지)과 행동의 일치를 강조하는 유교적 학문.",
    page: "104",
  },
  {
    id: 23,
    answer: "해금 정책",
    question:
      "명이 실시한 사적인 해상 무역을 제한하는 정책. 16세기 후반에 민간 무역을 허용.",
    page: "104",
  },
  {
    id: 24,
    answer: "마테오 리치",
    question:
      "명으로 간 선교사. 크리스트교를 소개하였고, 세계지도인 곤여만국전도를 만들어 중국인의 세계관 변화에 큰 영향을 줌.",
    page: "104",
  },
  {
    id: 25,
    answer: "일조편법",
    question:
      "명대에 시행된 여러 세금을 토지세와 인두세로 단순화하여 은으로 내게 한 제도.",
    page: "104",
  },
  {
    id: 26,
    answer: "청",
    question:
      "17세기 초 명이 약해지자 누르하치가 만주에 있던 여진의 여러 부족을 통합하여 세운 나라. 초기 국호는 후금.",
    page: "105",
  },
  {
    id: 27,
    answer: "화이관",
    question:
      "한족이 자신이 세운 국가를 중화로 여기고 그 외의 민족을 오랑캐로 여기는 관점. 청대에 변화가 생김.",
    page: "105",
  },
  {
    id: 28,
    answer: "건륭제",
    question:
      "활발한 정복 활동으로 최대 영토를 확보한 청의 전성기를 이끈 황제.",
    page: "105",
  },
  {
    id: 29,
    answer: "고증학",
    question:
      "청대에 발달한 현실 정치를 멀리하고 경전을 실증적으로 연구하는 학문.",
    page: "106",
  },
  {
    id: 30,
    answer: "공행",
    question:
      "18세기 중반 이후 청 정부로부터 허가를 받은 특허 상인. 이들만 외국 상인과 무역 가능.",
    page: "106",
  },
  {
    id: 31,
    answer: "지정은제",
    question:
      "청대에 시행한 사람에게 부과하는 인두세를 토지세에 포함하여 은으로 한꺼번에 내게 한 정책.",
    page: "106",
  },
  {
    id: 32,
    answer: "콜럼버스",
    question: "대서양으로 항해하여 최초로 아메리카에 도착한 유럽인.",
    page: "119",
  },
  {
    id: 33,
    answer: "마젤란",
    question: "태평양을 가로질러 최초로 세계 일주에 성공한 인물.",
    page: "119",
  },
  {
    id: 34,
    answer: "바스쿠 다 가마",
    question:
      "포르투갈 출신의 탐험가. 유럽에서 희망봉을 거쳐 아시아(인도)로 가는 항로 개척.",
    page: "119",
  },
  {
    id: 35,
    answer: "대서양",
    question: "신항로 개척 이후 지중해를 대신하여 무역의 중심지로 떠오른 곳.",
    page: "120",
  },
  {
    id: 36,
    answer: "삼각 무역",
    question:
      "대서양 무역에서 발전한 무역 형태로 유럽, 아메리카, 아프리카를 잇는 무역.",
    page: "120",
  },
  {
    id: 37,
    answer: "가격 혁명",
    question:
      "유럽에 아메리카 대륙으로부터 많은 양의 금, 은이 들어와 물가가 크게 오른 현상.",
    page: "120",
  },
  {
    id: 38,
    answer: "상업 혁명",
    question:
      "신항로 개척 이후 상업과 제조업이 발전되어 금융 제도가 발달한 현상. 근대 자본주의의 발달에 영향을 줌.",
    page: "120",
  },
  {
    id: 39,
    answer: "잉카 제국",
    question:
      "아스테카 제국과 함께 에스파냐에 의해 정복당한 아메리카의 고대 문명. 안데스고원에 위치.",
    page: "121",
  },
  {
    id: 40,
    answer: "노예 무역",
    question: "산업 혁명 이후 유럽인들이 아프리카 원주민을 노예로 동원한 일.",
    page: "121",
  },
  {
    id: 41,
    answer: "절대 왕정",
    question:
      "16~18세기 유럽에 나타난 국왕이 강력한 권한을 행사하는 정치 체계.",
    page: "123",
  },
  {
    id: 42,
    answer: "왕권신수설",
    question:
      "국왕의 권리는 신에게서 받은 것이라는 뜻. 왕권에 정당성을 부여함.",
    page: "123",
  },
  {
    id: 43,
    answer: "중상주의",
    question: "수출을 장려하고 관세를 높여 수입을 줄이는 정책.",
    page: "123",
  },
  {
    id: 44,
    answer: "펠리페 2세",
    question:
      "에스파냐의 국왕. 신대륙과 지중해, 네덜란드 등을 아우르는 대제국을 세우고, 무적함대를 만들어 지중해 해상권을 장악함.",
    page: "124",
  },
  {
    id: 45,
    answer: "엘리자베스 1세",
    question:
      "영국의 여왕. 에스파냐의 무적함대를 물리쳐 해상권을 장악하고, 해외 시장 개척에 적극적으로 나서며, 국내 산업을 발전시킴.",
    page: "124",
  },
  {
    id: 46,
    answer: "루이 14세",
    question:
      "프랑스의 국왕. 관료제와 상비군 정비, 중상주의 정책 강화, 베르사유 궁전 완성. 스스로를 태양왕이라 칭함.",
    page: "124",
  },
  {
    id: 47,
    answer: "표트르 대제",
    question:
      "러시아의 국왕. 발트해 확보, 상트페테르부르크 건설, 서구화 정책 실시.",
    page: "125",
  },
  {
    id: 48,
    answer: "프리드리히 2세",
    question:
      "프로이센(현재의 독일)의 국왕. 슐레지엔 지방 차지, 산업 장려, 종교적 관용 정책 실시, 상수시 궁전 건축.",
    page: "125",
  },
  {
    id: 49,
    answer: "과학 혁명",
    question: "17~18세기 뉴턴이 만유인력의 법칙 발견 등의 과학이 발전한 현상.",
    page: "126",
  },
  {
    id: 50,
    answer: "계몽 사상",
    question:
      "인간의 이성에 의한 진보를 믿는 사상. 미국 혁명, 프랑스 혁명의 사상적 기반이 됨.",
    page: "126",
  },
  {
    id: 51,
    answer: "젠트리",
    question: "귀족과 자영농 사이의 지주층, 16, 17세기 이후 성장.",
    page: "133",
  },
  {
    id: 52,
    answer: "청교도",
    question: "영국에 거주하던 칼뱅파 신도. 영국 의회에서 강한 세력을 이룸.",
    page: "133",
  },
  {
    id: 53,
    answer: "권리 청원",
    question:
      "제임스 1세와 찰스 1세가 의회의 권리를 무시하자 의회가 제출한 선언. '의회의 승인 없이 세금을 징수할 수 없다.'라는 내용을 담음.",
    page: "133",
  },
  {
    id: 54,
    answer: "청교도 혁명",
    question:
      "영국에서 일어난 내전. 의회파가 왕당파를 격파한 후 국왕 찰스 1세를 처형하고 공화정을 수립함.",
    page: "133",
  },
  {
    id: 55,
    answer: "크롬웰",
    question:
      "청교도 혁명에서 의회파를 이끌고 정권을 장악, 독재 정치를 실시하고 항해법을 제정한 사람.",
    page: "133",
  },
  {
    id: 56,
    answer: "명예혁명",
    question:
      "영국 의회가 제임스 2세를 몰아내고 공주인 메리와 남편 윌리엄을 공동 왕으로 세운 혁명.",
    page: "133",
  },
  {
    id: 57,
    answer: "권리 장전",
    question:
      "명예혁명 이듬해 의회가 왕에게 승인받은 법률. 이로 인해 영국에서 절대 왕정이 무너지고 의회를 중심으로 한 입헌 군주제의 토대가 마련됨.",
    page: "133",
  },
  {
    id: 58,
    answer: "보스턴 차 사건",
    question:
      "원주민 복장을 한 식민지인들이 보스턴항에 정박 중이던 영국 동인도 회사의 배를 습격해 차 상자를 바다에 던진 사건.",
    page: "134",
  },
  {
    id: 59,
    answer: "워싱턴",
    question:
      "식민지 대표들이 임명한 식민지 군대의 총사령관. 이후 미국의 초대 대통령으로 선출됨.",
    page: "134 ~ 135",
  },
  {
    id: 60,
    answer: "연방제",
    question:
      "각 주에 자치를 허용하되 통합적인 연방 정부를 두고, 연방 의회에서 입법과 과세 등을 결정하는 제도. 미국 헌법의 특징.",
    page: "135",
  },
  {
    id: 61,
    answer: "삼권 분립",
    question:
      "국가의 권력을 입법, 사법, 행정권으로 분리하여 서로 견제하도록 한 국가의 조직 원리. 미국 헌법에 규정됨.",
    page: "135",
  },
  {
    id: 62,
    answer: "남북 전쟁",
    question:
      "노예제로 인해 미국의 남부와 북부의 갈등이 심화되어 발발한 전쟁. 노예제를 반대한 북부가 승리.",
    page: "135",
  },
  {
    id: 63,
    answer: "제3 신분",
    question:
      "구제도에서 많은 차별을 받은 신분. 각종 세금에 시달리면서 정치적 권리는 거의 없었음.",
    page: "136",
  },
  {
    id: 64,
    answer: "삼부회",
    question:
      "루이 16세가 국가 재정이 어려워지자 소집한 신분제 의회. 이때 제3 신분은 머릿수에 따른 투표를 주장함.",
    page: "136",
  },
  {
    id: 65,
    answer: "국민 의회",
    question:
      "1789년 제3 신분 대표자들에 의해 생겨난 의회. 이후 입헌 군주제를 규정한 헌법을 제정함.",
    page: "136 ~ 137",
  },
  {
    id: 66,
    answer: "테니스코트의 서약",
    question:
      "제3 신분이 국민 의회를 결성하고 새로운 헌법이 제정될 때까지 해산하지 않겠다고 선언한 사건.",
    page: "136",
  },
  {
    id: 67,
    answer: "바스티유 감옥",
    question:
      "국왕이 국민 의회를 탄압하려 하자 분노한 시민들이 습격한 구제도의 상징인 감옥.",
    page: "136",
  },
  {
    id: 68,
    answer: "인권 선언",
    question: "1789년 국민 의회가 발표한 선언. 자유와 평등의 이념을 담음.",
    page: "136",
  },
  {
    id: 69,
    answer: "입법 의회",
    question: "헌법에 따라 국민 의회의 해산 이후 소집된 의회.",
    page: "137",
  },
  {
    id: 70,
    answer: "국민 공회",
    question:
      "입법 의회 대신 들어선 의회. 공화정을 선포하고 루이 16세를 처형함.",
    page: "137",
  },
  {
    id: 71,
    answer: "로베스피에르",
    question:
      "국민 공회 시기의 정치인. 공안 위원회와 혁명 재판소를 설치하여 공포 정치를 실시함.",
    page: "137",
  },
  {
    id: 72,
    answer: "총재 정부",
    question:
      "온건파의 로베스피에르 처형 이후 세워진 5명의 총재가 나라를 이끄는 정부.",
    page: "137",
  },
  {
    id: 73,
    answer: "나폴레옹",
    question:
      "1799년 쿠데타를 일으켜 정권을 장악한 프랑스의 장군. 오스트리아, 프로이센 등을 격파하고, 이후 제1 통령, 황제가 됨.",
    page: "137 ~ 139",
  },
  {
    id: 74,
    answer: "나폴레옹 법전",
    question: "나폴레옹이 편찬한 새로운 시민 사회의 규범을 담은 법전.",
    page: "139",
  },
  {
    id: 75,
    answer: "대륙 봉쇄령",
    question:
      "나폴레옹이 영국을 굴복시키기 위해 내린 유럽 대륙과 영국의 통상을 금지하는 명령. 러시아가 이를 어기자 러시아 원정에 나섰지만 참패함.",
    page: "139",
  },
  {
    id: 76,
    answer: "빈 회의",
    question:
      "나폴레옹이 몰락한 후 유럽 각국의 대표들이 전후의 혼란을 수습하기 위해 연 회의.",
    page: "140",
  },
  {
    id: 77,
    answer: "빈 체제",
    question:
      "빈 회의 때 성립된 자유주의와 민족주의 운동을 탄압하면서 보수적인 질서를 지키려는 체제.",
    page: "140",
  },
  {
    id: 78,
    answer: "7월 혁명",
    question:
      "프랑스 국왕 샤를 10세가 보수적인 정책을 펼치자 시민들이 혁명을 일으켜 입헌 군주제를 수립한 혁명.",
    page: "140",
  },
  {
    id: 79,
    answer: "2월 혁명",
    question:
      "루이 필리프가 시민들의 요구를 탄압하자 시민들이 일으킨 혁명. 이 혁명으로 프랑스는 왕정을 폐지하고 공화정이 수립됨.",
    page: "140",
  },
  {
    id: 80,
    answer: "차티스트 운동",
    question:
      "영국의 노동자들이 벌인 정치적 권리를 얻기 위해 인민헌장을 발표하고 이를 의회에 제출하기 위한 서명 운동.",
    page: "141",
  },
  {
    id: 81,
    answer: "가리발디",
    question: "의용대(붉은 셔츠대)를 거느리고 이탈리아 통일 운동에 참여함.",
    page: "142",
  },
  {
    id: 82,
    answer: "비스마르크",
    question:
      "프로이센의 재상. 철혈 정책을 내세워 군사력을 키워, 이를 바탕으로 프로이센은 오스트리아와 프랑스를 꺾고 독일 제국의 성립을 선포함.",
    page: "143",
  },
  {
    id: 83,
    answer: "알렉산드르 2세",
    question:
      "러시아의 황제. 농노 해방, 지방 의회 구성, 군사 제도 개혁 등 내정 개혁을 실시함.",
    page: "143",
  },
  {
    id: 84,
    answer: "브나로드 운동",
    question:
      "러시아의 지식인들이 농민을 계몽하고 혁명 정신을 전파하기 위해 펼친 운동.",
    page: "143",
  },
];
